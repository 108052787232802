<template>
  <div class="container">
    <div class="title">
      <span
        style="color:#409EFF;margin-right:10px;cursor: pointer;"
        @click="$router.back()"
      > <i class="el-icon-arrow-left"></i>返回
      </span>
      商品详情
    </div>
    <div class="content">
      <div class="goods_info">
        <div
          class="img_box"
          :style="goodsInfo.pic?'':'background-color: #ecf1fd;'"
        >
          <img
            v-if="goodsInfo.pic"
            :src="goodsInfo.pic"
            alt=""
          >
          <p v-else>暂无图片</p>
        </div>
        <div class="text_box">
          <div>
            <p style="font-weight:700;">{{goodsInfo.name}}</p>
            <p>需在实名认证通过后使用</p>
          </div>
          <div
            class="off_tip"
            v-if="!goodsInfo.status"
          >商品已下线</div>
        </div>
      </div>
      <div class="bottom_box">
        <el-tabs v-model="activeName">
          <el-tab-pane
            label="API文档"
            name="first"
          >
            <div class="doc_address">
              文档地址：
              <a
                href="https://www.apifox.cn/apidoc/shared-70ccfcc8-ba68-439c-9e94-1809c445bcfd"
                target="_blank"
              >
                https://www.apifox.cn/apidoc/shared-70ccfcc8-ba68-439c-9e94-1809c445bcfd
              </a>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="联系我们"
            name="second"
          >
            <div class="info_group">
              <div class="row">
                <p class="column">咨询电话</p>
                <p class="column">0371-61875566</p>
              </div>
              <div class="row">
                <p class="column">客服QQ</p>
                <p class="column">133983444</p>
              </div>
              <div class="row">
                <p class="column">公司地址</p>
                <p class="column">河南省郑州市花园北路66号黄河建工大厦B座3楼306室</p>
              </div>
            </div>
          </el-tab-pane>

        </el-tabs>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    return {
      activeName: 'first',
      goodsInfo: {}
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () {
    console.log(this.$route.params)
    this.goodsInfo = this.$route.params
  },
  mounted () { },
  // 组件方法
  methods: {

  }
}
</script>

<style scoped lang="less">
.container {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  .title {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #ededed;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .content {
    height: calc(100% - 35px);
    width: 100%;
    // padding: 20px 0 0 70px;
    box-sizing: border-box;
    overflow-y: auto;
    // justify-content: space-around;
    .goods_info {
      display: flex;
      align-items: center;
      height: 230px;
      border-bottom: 1px solid #ededed;
      .img_box {
        height: 180px;
        // min-width: 180px;

        margin-left: 20px;
        line-height: 180px;
        text-align: center;
        img {
          height: 100%;
        }
        p {
          width: 180px;
        }
      }
      .text_box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // align-items: center;
        height: 180px;
        width: 300px;
        margin-left: 20px;
        p {
          margin-top: 15px;
        }
        .off_tip {
          width: fit-content;
          padding: 10px 15px;
          background-color: #999999;
          color: #fff;
        }
      }
    }
    .bottom_box {
      padding: 10px 20px;
      /deep/.el-tabs__nav-wrap::after {
        background-color: #fff;
      }
      .doc_address {
        margin: 10px;
      }
      .info_group {
        width: 550px;
        border: 1px solid #dddddd;
        .row {
          display: flex;
          padding: 10px;
          border-bottom: 1px solid #dddddd;
          .column:first-child {
            width: 20%;
          }
          &:last-child {
            border: none;
          }
        }
      }
    }
  }
}
</style>
